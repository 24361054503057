import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/includes/defaults/header";

import Footer from "../components/includes/defaults/footer";
import QuickInfo from "../components/includes/quickInfo";
import Seo from "../components/seo";
import WhereWeAre from "../components/includes/where_we_are";

import Jotform from "react-jotform";

import Cta from "../components/CTA/CTAMain";

const SecondPage = () => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuerys {
			site {
				siteMetadata {
					title
					googleMap
					# gMap
					# mainArea
				}
			}
		}
	`);
	return (
		<>
			<div className="font-sans subpixel-antialiased">
				<Header />

				{/* <CTA /> */}
				<Cta background={true} />

				<section className="mt-10 mx-auto  max-w-screen-xl items-center mb-20 justify-between  ">
					<div className="grid grid-cols-1 md:grid-cols-12 gap-8">
						<div className="col-span-1 md:col-span-9">
							<div className="md:p-0 p-4">
								<Jotform
									className=" text-2xl"
									style={{
										width: "100%",
										height: "100%",
										border: "none",
										"background-color": "transparent",
									}}
									src="https://form.jotform.com/210292146957358"
									defaults={{
										name: " ",
									}}
								/>
							</div>
						</div>
						<div className="col-span-1 md:col-span-3 p-4 md:p-0 ">
							<QuickInfo />
						</div>
					</div>
					<WhereWeAre
						gMap={
							data.site.siteMetadata?.googleMap
								? data.site.siteMetadata?.googleMap
								: ""
						}
						area="Surey, UK"
					/>
				</section>
			</div>
			<Footer />
		</>
	);
};

export const Head = () => <Seo title="Contact Us" />;

export default SecondPage;
